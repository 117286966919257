import Cookies from 'js-cookie'

export function getAccessToken() {
    return Cookies.get('access_token')
}

export function setAccessToken(token,) {
    return Cookies.set('access_token', token)
}

export function removeAccessToken() {
    return Cookies.remove('access_token')
}

export function setUserInfo(info) {
    return Cookies.set('user_info', info)
}

export function getUserInfo() {
    return Cookies.getJSON('user_info')
}

export function removeUserInfo() {
    return Cookies.remove('user_info')
}

export function setUserLogin(info) {
    return Cookies.set('user_login', info)
}

export function getUserLogin() {
    return Cookies.getJSON('user_login')
}

export function removeUserLogin() {
    return Cookies.remove('user_login')
}

// 存储选择的水厂信息
export function setSelectWater(water) {
    return Cookies.set('select_water', water)
}

export function getSelectWater() {
    return Cookies.getJSON('select_water')
}

// 存储选择的泵站信息
export function setSelectPump(water) {
    return Cookies.set('select_pump', water)
}

export function getSelectPump() {
    return Cookies.getJSON('select_pump')
}

// 存储用户忘记密码时候返回的信息
export function setPassInfo(water) {
    return Cookies.set('pass_info', water)
}

export function getPassInfo() {
    return Cookies.getJSON('pass_info')
}
