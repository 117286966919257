import axios from 'axios'
import {Toast} from 'mint-ui';
import {getAccessToken, removeAccessToken} from '../utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000// request timeout
})


// request interceptor
service.interceptors.request.use(config => {
    const token = getAccessToken()
    if (token) {
        config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use(
    response => {
        const {status, statusText, data} = response
        // if the custom code is not 20000, it is judged as an error.
        if (status !== 200) {
            Toast({message: (statusText || 'Error', 'top'), duration: 2000});
            return Promise.reject(new Error(statusText || 'Error'))
        } else {

            const {success, message} = data
            if (success) {
                return response
            } else {
                Toast({message: message || '系统错误', duration: 2000});
                return Promise.reject(new Error(message || 'Error'))
            }

        }
    },
    error => {
        if (error.response) {
            let data = error.response.data
            const token = getAccessToken()
            console.log("------异常响应------", token)
            console.log("------异常响应------", error.response.status)
            switch (error.response.status) {
                case 403:
                    Toast({message: '拒绝访问', position: 'top', duration: 2000,});
                    break
                case 500:
                    if (data.message.indexOf("Token失效，请重新登录") == 0) {
                        removeAccessToken()
                        Toast({message: '登录过期，请重新登录', position: 'top', duration: 2000,});
                        setTimeout(() => {
                            window.location.href = location.protocol + '//' + location.host + '/app#/login'
                            // window.location.href = location.protocol + '//' + location.host + '/login'
                        }, 100)
                        return
                    }
                    Toast({message: data.msg, position: 'top', duration: 2000,});
                    break
                case 404:
                    Toast({message: '很抱歉，资源未找到!', position: 'top', duration: 2000,});
                    break
                case 504:
                    Toast({message: '网络超时!', position: 'top', duration: 2000,});
                    break
                case 401:
                    Toast({message: '未授权，请重新登录!', position: 'top', duration: 2000,});
                    if (token) {
                        removeAccessToken()
                        setTimeout(() => {
                            window.location.href = location.protocol + '//' + location.host + '/login'
                        }, 100)
                    }
                    break
                default:
                    Toast({message: (data.message || '服务器异常', 'top'), position: 'top', duration: 2000,});
                    break
            }
        }
        return Promise.reject(error)
    }
)

export {
    service as axios
}
