import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Ing from '../views/pump/View.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    { // 泵站列表
        path: '/pump/list',
        name: 'PumpList',
        component: () => import('../views/pump/List.vue')
    },
    { // 泵站工艺图
        path: '/pump/view',
        name: 'PumpView',
        component: () => import('../views/pump/View.vue')
    },
    { // 河龙口泵站
        path: '/pump/helongkou',
        name: 'PumpHelongkou',
        component: () => import('../views/pump/Helongkou.vue')
    },
    { // 泵站控制
        path: '/pump/control',
        name: 'PumpControl',
        component: () => import('../views/pump/Control.vue')
    },
    { // 泵站视频监控
        path: '/pump/video',
        name: 'PumpVideo',
        component: () => import('../views/pump/Video.vue')
    },
    { // 泵站数据查询-实时
        path: '/pump/real',
        name: 'PumpReal',
        component: () => import('../views/pump/Real.vue')
    },
    { // 泵站数据查询-历史
        path: '/pump/history',
        name: 'PumpHistory',
        component: () => import('../views/pump/History.vue')
    },
    { // 泵站数据查询
        path: '/pump/table',
        name: 'PumpTable',
        component: () => import('../views/pump/Table.vue')
    },
    { // 泵站数据查询
        path: '/pump/chart',
        name: 'PumpChart',
        component: () => import('../views/pump/Chart.vue')
    },
    { // 泵站数据单个实时数据查询
        path: '/pump/query/curve',
        name: 'PumpQueryCurve',
        component: () => import('../views/pump/Curve.vue')
    },
    { // 泵站报警记录
        path: '/pump/alert',
        name: 'PumpAlert',
        component: () => import('../views/pump/Alert.vue')
    },
    { // 水厂-工艺图
        path: '/water/view',
        name: 'WaterView',
        component: () => import('../views/water/View.vue')
    },
    { // 水厂-控制
        path: '/water/control',
        name: 'WaterControl',
        component: () => import('../views/water/Control.vue')
    },
    { // 水厂-实时数据
        path: '/water/real',
        name: 'WaterReal',
        component: () => import('../views/water/Real.vue')
    },
    { // 水厂-历史数据
        path: '/water/history',
        name: 'WaterHistory',
        component: () => import('../views/water/History.vue')
    },
    { // 水厂-历史-表格
        path: '/water/table',
        name: 'WaterTable',
        component: () => import('../views/water/Table.vue')
    },
    { // 水厂-历史-表格
        path: '/water/Chart',
        name: 'WaterChart',
        component: () => import('../views/water/Chart.vue')
    },
    { // 水厂-操作记录
        path: '/water/record',
        name: 'WaterRecord',
        component: () => import('../views/water/Record.vue')
    },
    { // 加药-工艺图
        path: '/drug/view',
        name: 'DrugView',
        component: () => import('../views/drug/View.vue')
    },
    { // 加药-控制
        path: '/drug/control',
        name: 'DrugControl',
        component: () => import('../views/drug/Control.vue')
    },
    { // 加药-操作记录
        path: '/drug/record',
        name: 'DrugRecord',
        component: () => import('../views/drug/Record.vue')
    },
    { // 视频监控
        path: '/video',
        name: 'Video',
        component: () => import('../views/video/Video.vue')
    },
    { // 视频监控
        path: '/Equipment',
        name: 'Equipment',
        component: () => import('../views/Equipment.vue')
    },
    { // 消息-报警
        path: '/msg/alert',
        name: 'MsgAlert',
        component: () => import('../views/msg/Alert.vue')
    },
    { // 消息-公告
        path: '/msg/notice',
        name: 'MsgNotice',
        component: () => import('../views/msg/Notice.vue')
    },
    { // 消息-公告详情
        path: '/msg/details',
        name: 'MsgDetails',
        component: () => import('../views/msg/Details.vue')
    },
    { // 个人中心
        path: '/user/index',
        name: 'UserIndex',
        component: () => import('../views/user/Index.vue')
    },
    { // 个人中心-忘记密码-发送短信
        path: '/user/mes',
        name: 'UserMes',
        component: () => import('../views/user/Mes.vue')
    },
    { // 个人中心-忘记密码-设置新密码
        path: '/user/new',
        name: 'UserNew',
        component: () => import('../views/user/New.vue')
    },
    { // 个人中心-操作记录
        path: '/user/record',
        name: 'UserRecord',
        component: () => import('../views/user/Record.vue')
    },
    { // 个人中心-系统设置
        path: '/user/set',
        name: 'UserSet',
        component: () => import('../views/user/Set.vue')
    },
    { // 个人中心-个人信息
        path: '/user/info',
        name: 'UserInfo',
        component: () => import('../views/user/Info.vue')
    },
    { // 个人中心-修改手机号码
        path: '/user/phone',
        name: 'UserPhone',
        component: () => import('../views/user/Phone.vue')
    },
    { // 个人中心-更改密码
        path: '/user/modify',
        name: 'UserModify',
        component: () => import('../views/user/Modify.vue')
    },
    { // 个人中心-关于我们
        path: '/user/about',
        name: 'UserAbout',
        component: () => import('../views/user/About.vue')
    },
    { // 个人中心-用户协议
        path: '/user/agreement',
        name: 'UserAgreement',
        component: () => import('../views/user/Agreement.vue')
    },
    { // 个人中心-隐私条款
        path: '/user/privacy',
        name: 'UserPrivacy',
        component: () => import('../views/user/Privacy.vue')
    },
    { // 个人中心-通讯录
        path: '/user/mail-list',
        name: 'UserMailList',
        component: () => import('../views/user/MailList.vue')
    },
    {
        path: '/download',
        name: 'Download',
        component: () => import('../views/Download.vue')
    },
    {
        path: '/update',
        name: 'Update',
        component: () => import('../views/Update.vue')
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
