import {getAction, deleteAction, putAction, postAction, httpAction} from '@/api/manage'
import {axios} from "../utils/request";

const api = {
    Login: '/sys/login',
    Logout: '/sys/logout',
    ForgePassword: '/auth/forge-password',
    Register: '/auth/register',
    SendSms: '/account/sms',
    UserInfo: '/user/info'
}
export default api
/**
 * 验证是否登录
 */

const getCheck = () => getAction('/check')

/**
 * 登录管理
 */
const login = (params) => postAction('/sys/mLogin', params)
const logout = (params) => postAction('/sys/logout', params)
const postSendSms = (params) => postAction('/sys/sendSms', params) // 发送短信 ｛mobile｝
const postVerification = (params) => postAction('/sys/user/phoneVerification', params) // 提交验证 ｛phone smscode｝
const getPasswordChange = (params) => getAction('/sys/user/passwordChange', params) // 重置密码

const postPhoneSendSms = (params) => postAction('/sys/sendSmsPhone', params) // 发送短信-更改手机号码 ｛mobile｝
const postPhoneVerification = (params) => postAction('/sys/user/phoneSmsVerification', params) // 提交更改手机号码验证 ｛phone smscode｝
const putModifyPassword = (params) => putAction('/sys/user/updatePassword', params) // 修改密码

const getMailList = (params) => getAction('/sys/user/queryPageNewList', params) // 通讯录查询通过所在部门ID


/**
 * 无人泵站socket
 */
const postPumpView = (params) => postAction('/dac/pumpData/initData', params) // 视图
const postPumpControl = (params) => postAction('/dac/pumpData/initControlData', params) // 控制
const postPumpPage = (params) => postAction('/dac/pumpData/initPageData', params) // 分页查询


/**
 * 智慧水厂socket
 */
const postWaterView = (params) => postAction('/water/data/initWater', params) // 视图

/**
 * 排泥socket
 */
const postSoilData = (params) => postAction('/water/data/initWaterMud', params)


/**
 * 加药socket
 */
const postDrugView = (params) => postAction('/water/data/initWaterPac', params) // 视图

/**
 * 水厂管理
 */
const getWaterList = (params) => getAction('/dac/waterWorks/queryList', params)
const getWaterListByOrgCode = (params) => getAction('/dac/waterWorks/queryListByOrgCode', params)

const getWaterWorksList = (params) => getAction('/water/waterWorks/queryList', params)

/**
 * 泵站管理
 */
const getPumpListByWaterId = (params) => getAction('/dac/pump/queryListByWaterId', params)

/**
 * 泵站操作管理
 */
const getPumpOperationPage = (params) => getAction('/dac/operateLog/list', params) // 分页操作管理

/**
 * 加药操作管理
 */
const getWaterPacLogPage = (params) => getAction('/water/waterPacLog/list', params) // 分页操作管理

/**
 * 排泥操作管理
 */
const getWaterMudLogPage = (params) => getAction('/water/waterMudLog/list', params) // 分页操作管理


/**
 * 消息管理
 */
const getPumpAlertMsgPage = (params) => getAction('/sys/sysAnnouncementSend/getMyAlertSend', params) // 报警消息列表
const putAlertMsgState = (params) => putAction('/sys/sysAnnouncementSend/editByAnntIdAndUserId', params) // 标记信息
const putAlertAllMsgState = (params) => putAction('/sys/sysAnnouncementSend/readAllByBusId', params) // 标记所有报警信息
const putTypeAllMsgState = (params) => putAction('/sys/sysAnnouncementSend/readAllByUserId', params) // 标记类型消息读取状态
const getMsgInfo = (params) => getAction('/sys/annountCement/queryById', params) // 公共详情
const getMsgCount = () => getAction('/sys/annountCement/totalNum') // 获取未读消息条数


/**
 * 网关管理
 */
const getGatewayList = (params) => getAction('/dacGateway/queryList', params)
/**
 * 从机管理
 */
const getEquipmentList = (params) => getAction('/dacEquipment/getEquipmentBySin', params)
const getVariableDataPage = (params) => getAction('/dacVariableData/queryPageListByEqId', params)
/**
 * 变量配置
 */
const getVariableList = (params) => getAction('/dacVariable/queryListBySin', params)
const getDataHistoryPage = (params) => getAction('/dataHistory/queryPageList', params) // 变量历史数据-通过sin时间段获取分页数据
const getDataHistoryList = (params) => getAction('/dataHistory/queryModelList', params)
const getVariableTableTitle = (params) => getAction('/dacVariable/getVariableTableTotal', params) //获取表格表头集合
const getRealTimeVariable = (params) => getAction('/dacVariableData/queryNowListByVariableId', params) //变量数据表-通过variableId查询单个或多个实时数据,逗号隔开
const getRealPage = (params) => getAction('/dacVariableData/queryDataByPageNo', params) //通过页面标识获取实时数据2取水泵3送水泵4加药系统
const postSetPars = (params) => postAction('/dacVariableData/setPars', params) //更改变量参数
const postStartOrStop = (params) => postAction('/dacVariableData/startOrStop', params) //远程控制变量

/**
 * APP推送管理
 */
const postAppPush = (params) => postAction('/dac/appPush/add', params)
const getMsg = (params) => getAction('/msg/queryMsg', params)
const getPushState = (params) => getAction('/dac/appPush/getOneCid', params)
const putPushState = (params) => putAction('/dac/appPush/edit', params)


/**
 * 泵站分组管理
 */
const getVariableListByPumpId = (params) => getAction('/dac/pumpGroup/queryListByVo', params)
const getVariableNameByIds = (params) => getAction('/dac/pumpGroup/queryNameListByIds', params)


/**
 * 历史数据
 */
const getHistoryPage = (params) => getAction('/dac/historyData/queryNewPageByVo', params)
const getHistoryList = (params) => getAction('/dac/historyData/queryMoreByVo', params)

/**
 * 实时数据
 */
const getCurveData = (params) => getAction('/dac/variableData/queryOneDataByVariableId', params) // 获取单个变量或多个变量实时数据
const postRemoteControl = (params) => postAction('/dac/variableData/remoteControl', params) // 远程批量控制变量
const getPumpView = (params)=> getAction('/dac/variableData/queryPumpNewDataByPumpId',params) // 泵站工艺图实时数据

/**
 * 网关状态
 */
const getGatewayState = (params) => getAction('/dacGatewayState/queryGatewayState', params) //获取网关状态信息 new

/**
 * 操作记录
 */
const getLogPage = (params) => getAction('/appLog/list', params) //获取网关状态信息 new


const putGateway = (params) => putAction('/dacGateway/edit', params) //通过机构orgCode获取网关列表


// 视频监控获取token
const postVideoToken = (params) => postAction('/dac/video/queryToken', params)
const getVideoList = (params) => getAction('/dac/video/queryVideoList', params)


/**
 * 实时数据控制
 */
const postVariableData = (params) => postAction('/dac/variableData/startOrStop', params) // 远程控制变量
const getPumpListByPumpId = (params) => getAction('/dac/variableData/querySortDataByPumpId', params) // 通过泵站ID获取泵主备排序

/**
 * 远程控制变量
 */
const postWaterVariableData = (params) => postAction('/water/variableData/startOrStop', params) // 远程控制变量


// 用到的接口未涉及
const postSwitchControl = (params) => postAction('/variable/switchControl', params)//远程控制变量
const getLogout = () => getAction('/logout') //APP退出登录
const getUserInfoByToken = () => getAction('/sys/user/queryOneById') //获取当前用户信息
export {
    getCheck,
    login,
    logout,
    postSendSms,
    postVerification,
    getPasswordChange,
    postPhoneSendSms,
    postPhoneVerification,
    putModifyPassword,
    getMailList,
    postPumpView,
    postWaterView,
    postSoilData,
    postDrugView,
    postPumpControl,
    postPumpPage,
    getWaterList,
    getWaterListByOrgCode,
    getWaterWorksList,
    getPumpListByWaterId,
    getPumpOperationPage,
    getWaterPacLogPage,
    getWaterMudLogPage,
    getPumpAlertMsgPage,
    putAlertMsgState,
    putAlertAllMsgState,
    putTypeAllMsgState,
    getMsgInfo,
    getMsgCount,
    getGatewayList,
    getEquipmentList,
    getVariableDataPage,
    getVariableList,
    getDataHistoryPage,
    getDataHistoryList,
    getVariableTableTitle,
    getRealTimeVariable,
    getRealPage,
    postSetPars,
    postStartOrStop,
    getGatewayState,
    postAppPush,
    getMsg,
    getPushState,
    putPushState,
    getVariableListByPumpId,
    getVariableNameByIds,
    getHistoryPage,
    getHistoryList,
    getCurveData,
    postRemoteControl,
    getPumpView,
    postSwitchControl,
    getLogout,
    getUserInfoByToken,
    getLogPage,
    putGateway,
    postVideoToken,
    getVideoList,
    postVariableData,
    getPumpListByPumpId,
    postWaterVariableData,
}
