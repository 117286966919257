import Vue from 'vue'
import App from './App.vue'
// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// console.log(vConsole.version)
import './registerServiceWorker'
import router from './router'
import store from './store'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import './utils/ydui.flexible.js'
import mui from './utils/mui.min.js'

import ba from 'vue-ba' // 百度统计

Vue.use(ba, '044fc616eb62dc219223beb18c51d957');

// Vue.prototype.mui = mui
Vue.config.productionTip = false
Vue.use(MintUI)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
