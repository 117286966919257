import {axios} from '../utils/request'

export function login(data) {
  const params = {...data}
  return axios({
    url: '/app/login/login',
    method: 'post',
    data: params
  })
}

export function logout(logoutToken) {
  return axios({
    url: '/sys/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Access-Token':  logoutToken
    }
  })
}
