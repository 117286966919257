const sundries = {
    state: {
        pumpViewData: {},// 无人泵站 视图
        pumpControlData: [],// 无人泵站 控制
        pumpPageData: {},// 无人泵站 查询
        waterViewData: {},// 智慧水厂 视图
        drugViewData: {},// 加药系统 视图
        soilData: {
            pars:{},
            data:[]
        },// 排泥
    },
    mutations: {
        SET_PUMP_VIEW_DATA: (state, data) => {
            state.pumpViewData = data
        },
        SET_PUMP_CONTROL_DATA: (state, data) => {
            state.pumpControlData = data
        },
        SET_PUMP_PAGE_DATA: (state, data) => {
            state.pumpPageData = data
        },
        SET_WATER_APP_WATER: (state, data) => {
            state.waterViewData = data
        },
        SET_DRUG_APP_PAC: (state, data) => {
            state.drugViewData = data
        },
        SET_SOIL_APP_MUD: (state, data) => {
            state.soilData = data
        },
    },
    actions: {
        PUMP_VIEW_DATA({commit}, data) { // 无人泵站-工艺图
            commit('SET_PUMP_VIEW_DATA', data)
        },
        PUMP_CONTROL_DATA({commit}, data) { // 无人泵站-控制
            commit('SET_PUMP_CONTROL_DATA', data)
        },
        PUMP_PAGE_DATA({commit}, data) { // 无人泵站-分页数据
            commit('SET_PUMP_PAGE_DATA', data)
        },
        WATER_APP_WATER({commit}, data) { // 智慧水厂 - 工艺图
            commit('SET_WATER_APP_WATER', data)
        },
        DRUG_APP_PAC({commit}, data) { // 智慧水厂 - 工艺图
            commit('SET_DRUG_APP_PAC', data)
        },
        SOIL_APP_MUD({commit}, data) { // 智慧水厂 - 工艺图
            commit('SET_SOIL_APP_MUD', data)
        },
    }
}

export default sundries
