import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store/index.js'
import {getUserInfo} from "@/utils/auth";

Vue.use(Vuex)
export default {
    initWebSocket: function () {
        // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
        let userInfo = getUserInfo()
        var url = process.env.VUE_APP_SOCKET_API + "/websocket/" + userInfo.id;
        this.websock = new WebSocket(url);
        this.websock.onopen = this.websocketOnopen;
        this.websock.onerror = this.websocketOnerror;
        this.websock.onmessage = this.websocketOnmessage;
        this.websock.onclose = this.websocketOnclose;
    },
    websocketOnopen: function () {
        console.log("WebSocket连接成功");
        //心跳检测重置
        // this.heartCheck.reset().start();
    },
    websocketOnerror: function () {
        console.log("WebSocket连接发生错误");
        this.reconnect();
    },
    websocketOnmessage: function (e) {
        var data = eval("(" + e.data + ")"); //解析对象
        switch (data.cmd) {
            case "PUMP": // 无人泵站 关键指标
                store.dispatch('PUMP_VIEW_DATA', data.data.result)
                break;
            case "PUMP_CONTROL": // 无人泵站 控制
                store.dispatch('PUMP_CONTROL_DATA', data.data)
                break;
            case "PUMP_DATA": // 无人泵站 查询分页数据
                store.dispatch('PUMP_PAGE_DATA', data.data)
                break;
            case "APP_WATER": // 水厂 工艺图
                store.dispatch('WATER_APP_WATER', data.data)
                break;
            case "APP_PAC": // 加药系统 工艺图
                store.dispatch('DRUG_APP_PAC', data.data.result)
                break;
            case "APP_MUD": // 排泥
                store.dispatch('SOIL_APP_MUD', data.data.result)
                break;
            default:
                console.log("else")
                break;
        }
        //心跳检测重置
        // this.heartCheck.reset().start();
    },
    websocketOnclose: function (e) {
        if (e) {
            console.log("connection closed (" + e.code + ")");
        }
        this.reconnect();
    },
    websocketSend(text) { // 数据发送
        try {
            this.websock.send(text);
        } catch (err) {
            console.log("send failed (" + err.code + ")");
        }
    },
    reconnect() {
        var that = this;
        if (that.lockReconnect) return;
        that.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        setTimeout(function () {
            console.info("尝试重连...");
            that.initWebSocket();
            that.lockReconnect = false;
        }, 8000);
    },
    heartCheckFun() {
        var that = this;
        //心跳检测,每20s心跳一次
        that.heartCheck = {
            timeout: 20000,
            timeoutObj: null,
            serverTimeoutObj: null,
            reset: function () {
                clearTimeout(this.timeoutObj);
                //clearTimeout(this.serverTimeoutObj);
                return this;
            },
            start: function () {
                // var self = this;
                this.timeoutObj = setTimeout(function () {
                    //这里发送一个心跳，后端收到后，返回一个心跳消息，
                    //onmessage拿到返回的心跳就说明连接正常
                    that.websocketSend("HeartBeat");
                    console.info("客户端发送心跳");
                    //self.serverTimeoutObj = setTimeout(function(){//如果超过一定时间还没重置，说明后端主动断开了
                    //  that.websock.close();//如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
                    //}, self.timeout)
                }, this.timeout)
            }
        }
    },

}
