<template>
  <div id="app">
    <div class="ly-wrap" v-if="!showHead">
      <div class="app-head"></div>
    </div>
    <router-view/>
  </div>
</template>
<script>
import {getAccessToken} from './utils/auth'
import {isMobile} from "@/utils/util"
import socket from "@/utils/socket";

export default {
  name: 'app',
  watch: {
    // 如果路由有变化，会再次执行该方法 验证标题是否跟这个方法有关系
    '$route': 'fetchDate'
  },
  mounted() {
    //初始化websocket
    this.onSocketOpen()
  },
  computed: {
    showHead() {
      return isMobile()
    },
  },
  methods: {
    onSocketOpen: function () {
      socket.initWebSocket(); //对应要连接的socket
    },
    async fetchDate() {
      await getAccessToken()
      const token = getAccessToken()
      if (!token) {
        let path = this.$route.path;
        if (path.indexOf('/pump/video') == 0 || path.indexOf('/login') == 0 || path.indexOf('/user/privacy') == 0 || path.indexOf('/user/agreement') == 0 || path.indexOf('/user/mes') == 0 || path.indexOf('/user/new') == 0 || path.indexOf('/download') == 0) {
          return
        }
        window.location.href = location.protocol + '//' + location.host + '/app#/login'
        // window.location.href = location.protocol + '//' + location.host + '/login'
        return
      }
      // push消息
      // if (isMobile()) {
      //   const _self = this;
      //   const _handlePush = function (message) {
      //     _self.handleGo()
      //   };
      //   plus.push.addEventListener('click', _handlePush);
      //   plus.push.addEventListener('receive', _handlePush);
      // }
    },

    handleGo() {
      window.location.href = location.protocol + '//' + location.host + '/app#/msg/alert'
    },

  },
  destroyed: function () { // 离开页面生命周期函数
    // socket.websocketOnclose()
  },
}
</script>
<style lang="less">
@import '~@/assets/less/base.less';
</style>

