<template>
  <div class="footer-wrap">
    <div class="ly-foot-tab">
      <div class="home" v-track-event="'主页, tab'" :class="[footOn==='home'?'home-on':'']" @click="goWay('/')">
        <p>主页</p>
      </div>
      <div class="msg" v-track-event="'消息, tab'" :class="[footOn==='msg'?'msg-on':'']" @click="goWay('/msg/alert')">
        <p>消息</p>
        <span class="count" v-if="count && count>0">{{ count }}</span>
      </div>
      <div class="user" v-track-event="'我的, tab'" :class="[footOn==='user'?'user-on':'']" @click="goWay('/user/index')">
        <p>我的</p>
      </div>
    </div>
  </div>
</template>

<script>
import {getMsgCount} from '@/api/api.js'

export default {
  name: "LyFootTab",
  props: {
    footOn: {
      type: String,
      default: 'home'
    }
  },
  components: {},
  data() {
    return {
      count: 0
    }
  },
  computed: {},
  created() {
    this.loadBase()
  },
  mounted() {
  },

  methods: {
    loadBase() {
      getMsgCount().then(res => {
        this.count = res.data.result
      })
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  }
}
</script>

<style scoped lang="less">
.footer-wrap {
  width: 7.5rem;
  height: 1.2rem;
  bottom: 0;
  box-shadow: 0 -0.04rem 0 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  background: #FFFFFF;

  .ly-foot-tab {
    width: 7rem;
    height: 1.2rem;
    display: flex;
    margin-left: 0.25rem;

    div {
      flex: 1;
      height: 1.2rem;
      background-repeat: no-repeat;
      background-size: 0.44rem;
      background-position: center 0.2rem;

      p {
        text-align: center;
        padding-top: 0.7rem;
        font-size: 0.2rem;
        font-weight: bold;
        color: #999999;
      }
    }

    .home {
      background-image: url("~@/assets/img/home/home.png");
    }

    .msg {
      position: relative;
      background-image: url("~@/assets/img/home/msg.png");

      .count {
        min-width: 0.25rem;
        padding: 0.02rem 0.03rem;
        text-align: center;
        background-color: red;
        border-radius: 99%;
        color: #FFFFFF;
        font-size: 10px;
        position: absolute;
        left: 1.38rem;
        top: 0.1rem;
      }
    }

    .user {
      background-image: url("~@/assets/img/home/user.png");
    }

    .home-on {
      background-image: url("~@/assets/img/home/home-on.png");

      p {
        color: #0088FF;
      }
    }

    .msg-on {
      background-image: url("~@/assets/img/home/msg-on.png");

      p {
        color: #0088FF;
      }
    }

    .user-on {
      background-image: url("~@/assets/img/home/user-on.png");

      p {
        color: #0088FF;
      }
    }
  }
}


</style>
