<template>
  <div class="home ly-wrap">
    <div class="header-wrap">
      <div class="welcome">欢迎您，{{ userInfo.realname }}！</div>
      <div class="water-name" @click="popupVisible=true" v-track-event="'水厂选择, 切换'">
        <div class="icon"></div>
        {{ selectWater.name }}
      </div>
      <div class="tips">*点击切换水厂</div>
    </div>
    <mt-popup
        class="menu-pop-wrap"
        v-model="popupVisible"
        position="left"
        popup-transition="popup-fade">
      <div class="menu-info">
        <div class="menu-title">
          <div class="icon"></div>
          请选择水厂
        </div>
        <div class="menu-list">
          <div class="menu-item" :class="[isSelect===item.id?'on':'']" v-for="item in waterList"
               v-track-event="'水厂选择, 选定'"
               :key="item.id" @click="handleWaterChoose(item)">
            <div class="name">
              <div class="icon"></div>
              {{ item.name }}
            </div>
            <div class="address">地址：{{ item.waterAddress }}</div>
            <div class="phone">负责人：{{ item.users }} <span>{{ item.userPhone }}</span></div>
            <div class="arrow"></div>
          </div>
        </div>

        <div class="back" @click="popupVisible=false" v-track-event="'水厂选择, 返回'">
          <div class="icon"></div>
          返回
        </div>
      </div>
    </mt-popup>
    <div class="body-wrap">
      <div class="big-box">
        <div class="ly-center">
          <div class="big-item on" v-track-event="'泵站工艺, 进入'" @click="goWay('/pump/view')">
            <div class="icon wrbz"></div>
            <div class="txt-box">
              <div class="name">泵站工艺</div>
              <div class="sub-txt">Pump Station</div>
            </div>
          </div>
          <div class="big-item on" v-track-event="'数据查询, 进入'" @click="goWay('/pump/real')">
            <div class="icon sjcx"></div>
            <div class="txt-box">
              <div class="name">数据查询</div>
              <div class="sub-txt">Data Query</div>
            </div>
          </div>
          <div class="big-item on" v-track-event="'报警管理, 进入'" @click="goWay('/pump/alert')">
            <div class="icon bjgl"></div>
            <div class="txt-box">
              <div class="name">报警管理</div>
              <div class="sub-txt">Alarm</div>
            </div>
          </div>
          <div class="big-item on" v-track-event="'视频监控, 进入'" @click="goWay('/pump/video')">
            <div class="icon spjk"></div>
            <div class="txt-box">
              <div class="name">视频监控</div>
              <div class="sub-txt">Surveillance</div>
            </div>
          </div>
          <div class="big-item on" v-track-event="'操作日志, 进入'" @click="goWay('user/record')">
            <div class="icon czrz"></div>
            <div class="txt-box">
              <div class="name">操作日志</div>
              <div class="sub-txt">Operation log</div>
            </div>
          </div>
          <div class="big-item on" v-track-event="'设备管理, 进入'" @click="goWay('/equipment')">
            <div class="icon sbgl"></div>
            <div class="txt-box">
              <div class="name">设备管理</div>
              <div class="sub-txt">Equipment</div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="division-line"></div>
      <div class="small-box">
        <div class="more-txt">·<span>更多功能</span>·</div>
        <div class="ly-center">
          <div class="small-item" v-track-event="'水质检测, 点击'" @click="handleNo">
            <div class="icon szjc"></div>
            <div class="txt">水质检测</div>
          </div>
          <div class="small-item" v-track-event="'漏损管理, 点击'" @click="handleNo">
            <div class="icon lsgl"></div>
            <div class="txt">漏损管理</div>
          </div>
          <div class="small-item" v-track-event="'加药系统, 点击'" @click="handleNo">
            <div class="icon jyxt"></div>
            <div class="txt">加药系统</div>
          </div>
          <div class="small-item" v-track-event="'category, 点击'" @click="handleNo">
            <div class="icon xjxt"></div>
            <div class="txt">巡检系统</div>
          </div>
        </div>
      </div>
    </div>

    <ly-foot-tab footOn="home"></ly-foot-tab>

  </div>
</template>

<script>
import {getWaterListByOrgCode, getWaterList, getPumpListByWaterId} from '../api/api.js'
import LyFootTab from '../components/LyFootTab'
import {getUserInfo, setSelectWater, getSelectWater, setSelectPump} from '../utils/auth'
import {Toast} from "mint-ui";

export default {
  name: 'Home',
  components: {LyFootTab},
  data() {
    return {
      waterList: [],
      selectWater: {},
      userInfo: {},
      isSelect: null,
      popupVisible: false
    }
  },
  created() {
    this.loadBase()
  },
  methods: {
    async loadBase() {
      getWaterList()
      this.userInfo = await getUserInfo()
      this.selectWater = await getSelectWater()

      if (this.userInfo.orgCode) {
        let body = {
          orgCode: this.userInfo.orgCode
        }
        getWaterListByOrgCode(body).then(res => {
          this.waterList = res.data.result

          // 判断cookie中是否有用户默认选中的水厂信息
          if (this.selectWater && this.selectWater.id) {
            this.isSelect = this.selectWater.id
            setSelectWater(this.selectWater)
            this.loadPump(this.selectWater.id)
            return
          }

          this.selectWater = res.data.result[0]
          setSelectWater(res.data.result[0])
          this.isSelect = res.data.result[0].id
          this.loadPump(res.data.result[0].id)
        })
      } else {
        this.goWay("/login")
      }
    },
    // 因为去掉了泵站列表 所有现在需要选择水厂后手动设置默认泵站
    loadPump(id) {
      let body = {
        waterId: id
      }
      getPumpListByWaterId(body).then(res => {
        if (res.data.result && res.data.result.length == 1) {
          setSelectPump(res.data.result[0])
        }
      })
    },
    handleWaterChoose(item) {
      this.selectWater = item
      this.isSelect = item.id
      setSelectWater(item)
      this.popupVisible = false
    },
    handleNo() {
      Toast("此功能暂未开通，请联系管理员！")
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  }
}
</script>
<style scoped lang="less">


.home {
  width: 100%;
  height: auto;
  background-color: #EDF6FF;
  position: relative;
  padding-bottom: 1.5rem;
}


/* 头部 */
.header-wrap {
  height: 3.83rem;
  //height: 4.2rem;
  background: #DEEFFF url("../assets/img/home/header-bg.gif") no-repeat center;
  background-size: 7.5rem;
  position: relative;

  .welcome {
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-indent: 0.5rem;
    position: absolute;
    top: 1rem;
  }

  .water-name {
    display: inline-block;
    height: 0.64rem;
    line-height: 0.64rem;
    background: #DEEFFF;
    box-shadow: 0 8px 12px 0 rgba(0, 65, 168, 0.5);
    border-top-right-radius: 0.32rem;
    border-bottom-right-radius: 0.32rem;
    padding: 0 0.4rem 0 0.2rem;
    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #0088FF;
    position: absolute;
    top: 1.6rem;

    .icon {
      width: 0.38rem;
      height: 0.32rem;
      display: inline-block;
      background: url("../assets/img/home/menu.png") no-repeat center;
      background-size: cover;
      position: relative;
      top: 0.04rem;
    }
  }

  .tips {
    font-size: 0.2rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 0.5rem;
    text-indent: 0.5rem;
    position: absolute;
    top: 2.4rem;
  }
}

/* 内容 */
.body-wrap {
  .clear {
    clear: both;
  }

  //  大的盒子
  .big-box {
    background-color: #DEEFFF;
    padding-top: 5%;
    padding-bottom: 2%;

    .big-item {
      width: 3.43rem;
      height: 1.84rem;
      background: url("~@/assets/img/home/item-bg.png") repeat;
      background-size: 0.28rem;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.13);
      border-radius: 10px;
      float: left;
      margin-right: 0.14rem;
      margin-bottom: 0.16rem;
      display: flex;
      position: relative;
      color: #999999;

      .icon {
        width: 1.44rem;
        height: 1.44rem;
        background-repeat: no-repeat;
        background-size: 1.44rem;
        background-position: center;
        margin-top: 0.2rem;
        margin-left: 0.2rem;
        margin-right: 0.1rem;
      }

      .wrbz {
        background-image: url("~@/assets/img/home/wrbz.png");
      }

      .sjcx {
        background-image: url("~@/assets/img/home/sjcx.png");
      }

      .bjgl {
        background-image: url("~@/assets/img/home/bjgl.png");
      }

      .czrz {
        background-image: url("~@/assets/img/home/czrz.png");
      }

      .zhsc {
        background-image: url("~@/assets/img/home/zhsc.png");
      }


      .spjk {
        background-image: url("~@/assets/img/home/spjk.png");
      }

      .nhgl {
        background-image: url("~@/assets/img/home/nhgl.png");
      }

      .sbgl {
        background-image: url("~@/assets/img/home/sbgl.png");
      }

      .txt-box {
        flex: 1;

        .name {
          font-size: 0.3rem;
          font-family: PingFang SC;
          font-weight: bold;
          margin-top: 0.6rem;
        }

        .sub-txt {
          font-size: 0.14rem;
          line-height: 0.3rem;
        }
      }

    }

    .on {
      box-shadow: 0 0.06rem 0.1rem 0 rgba(0, 0, 0, 0.13);
      border-radius: 10px;
      color: #0088FF;

      .mark {
        width: 0.69rem;
        height: 0.69rem;
        position: absolute;
        background: url("~@/assets/img/home/mark.png") no-repeat center;
        background-size: 0.69rem;
        top: 0;
        right: 0;
      }
    }

    .big-item:nth-child(2n) {
      margin-right: 0;
    }
  }

  //  分割
  .division-line {
    width: 100%;
    height: 0.04rem;
    background: url("~@/assets/img/home/division.png") repeat-x;
    background-size: 0.04rem;
  }

  //  小的盒子
  .small-box {
    background-color: #EDF6FF;
    padding-top: 3%;

    .more-txt {
      height: 0.8rem;
      line-height: 0.8rem;
      padding-bottom: 2%;
      text-align: center;
      color: #0088FF;

      span {
        padding: 0 0.12rem
      }
    }

    .ly-center {
      display: flex;

      .small-item {
        width: 1.96rem;
        height: 1.96rem;
        flex: 1;
        background: url("~@/assets/img/home/item-bg.png") repeat;
        background-size: 0.28rem;
        box-shadow: 0 0.03rem 0.05rem 0 rgba(0, 0, 0, 0.13);
        border-radius: 10px;
        margin-right: 0.15rem;

        .icon {
          width: 1.28rem;
          height: 1.38rem;
          background-repeat: no-repeat;
          background-position: center bottom;
          background-size: 1.28rem;
          margin: 0 auto;
        }

        .szjc {
          background-image: url("~@/assets/img/home/szjc.png");
        }

        .lsgl {
          background-image: url("~@/assets/img/home/lsgl.png");
        }

        .jyxt {
          background-image: url("~@/assets/img/home/jyxt.png");
        }

        .gisgw {
          background-image: url("~@/assets/img/home/gisgw.png");
        }

        .xjxt {
          background-image: url("~@/assets/img/home/xjxt.png");
        }

        .txt {
          font-size: 0.22rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          text-align: center;
        }
      }

      .small-item:last-child {
        margin-right: 0;
      }
    }


  }
}

/* 页脚 */
.footer-wrap {
  .mint-tabbar {
    background: #ffffff;

    .mint-tab-item {
      padding: 0.15rem 0;
    }
  }

  .mint-tabbar > .mint-tab-item.is-selected {
    background-color: #FFFFFF;
  }
}

/* 弹框 */
.menu-pop-wrap {
  width: 5.84rem;
  height: 100%;

  .menu-info {
    width: 5.84rem;
    height: 100%;
    background: url("~@/assets/img/home/menu-bg.png") repeat center;
    background-size: 0.01rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    .menu-title {
      height: 1.28rem;
      background: #F2F2F2;
      box-shadow: 0px 2px 2px 0px rgba(14, 15, 15, 0.4);
      padding-left: 0.48rem;
      font-size: 0.36rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0088FF;
      line-height: 1.28rem;

      .icon {
        width: 0.3rem;
        height: 0.34rem;
        background: url("~@/assets/img/home/point.png") no-repeat center;
        background-size: 0.3rem;
        display: inline-block;
        position: relative;
        top: 0.05rem;
      }
    }

    .menu-list {
      width: 100%;
      max-height: 10rem;
      overflow-y: scroll;
      padding-top: 0.42rem;

      .menu-item {
        width: 4.30rem;
        height: 1.19rem;
        background: #FFFFFF;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.13);
        border-radius: 10px;
        margin-bottom: 0.24rem;
        margin-left: 0.48rem;
        padding: 0.28rem 0.23rem 0.29rem 0.35rem;
        position: relative;

        .name {
          font-size: 0.3rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }

        .icon {
          width: 0.32rem;
          height: 0.29rem;
          display: inline-block;
          background: url("~@/assets/img/home/water.png") no-repeat center;
          background-size: 0.32rem;
          position: relative;
          top: 0.05rem;
        }

        .address {
          font-size: 0.22rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          height: 0.5rem;
          line-height: 0.5rem;
        }

        .phone {
          width: 80%;
          font-size: 0.22rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;

          span {
            float: right;
          }
        }

        .arrow {
          width: 0.29rem;
          height: 0.25rem;
          background: url("~@/assets/img/home/arrow.png") no-repeat center;
          background-size: 0.29rem;
          position: absolute;
          top: 0.31rem;
          right: 0.23rem;
        }
      }

      .on {
        background: #0088FF;
        box-shadow: 0px 2px 3px 0px rgba(0, 136, 255, 0.34);

        .name {
          color: #FFFFFF;
        }

        .address {
          color: #FFFFFF;
        }

        .phone {
          color: #FFFFFF;
        }

        .icon {
          background: url("~@/assets/img/home/water-on.png") no-repeat center;
          background-size: 0.32rem;
        }

        .arrow {
          background: url("~@/assets/img/home/arrow-on.png") no-repeat center;
          background-size: 0.29rem;
        }
      }
    }

    .back {
      width: 100%;
      height: 0.88rem;
      background: #FFFFFF;
      text-align: center;
      position: absolute;
      bottom: 0;
      line-height: 0.88rem;
      font-size: 0.3rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.13);

      .icon {
        width: 0.17rem;
        height: 0.29rem;
        display: inline-block;
        background: url("~@/assets/img/home/back.png") repeat center;
        background-size: 0.17rem;
        position: relative;
        top: 0.05rem;
        margin-right: 0.1rem;
      }
    }
  }
}

@import '~@/assets/less/ing.less';
</style>
