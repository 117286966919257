import Vue from 'vue'
import {login, logout} from "@/api/login"
import {
    ACCESS_SESSION,
    ACCESS_TOKEN,
    USER_NAME,
    USER_INFO,
    USER_AUTH,
    SYS_BUTTON_AUTH,
    UI_CACHE_DB_DICT_DATA,
    TENANT_ID,
    CACHE_INCLUDED_ROUTES
} from "@/store/mutation-types"
import {welcome} from "@/utils/util"
import {getAction} from '@/api/manage'

const user = {
    state: {
        userInfo: {},
        session: '',
        token: '',
        username: '',
        realname: '',
        tenantid: '',
        welcome: '',
        avatar: '',
        permissionList: [],
        info: {}
    },

    mutations: {
        SET_USER_INFO: (state, info) => {
            state.userInfo = info
        },
        SET_SESSION: (state, session) => {
            state.session = session
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, {username, realname, welcome}) => {
            state.username = username
            state.realname = realname
            state.welcome = welcome
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_PERMISSIONLIST: (state, permissionList) => {
            state.permissionList = permissionList
        },
        SET_INFO: (state, info) => {
            state.info = info
        },
        SET_TENANT: (state, id) => {
            state.tenantid = id
        },
    },

    actions: {
        USER_INFO({commit}, userInfo) {
            commit('SET_USER_INFO', userInfo)
        },
        // 登录
        Login({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    if (response.code == '200') {
                        const result = response.result
                        const userInfo = result.userInfo
                        Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
                        Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
                        Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
                        Vue.ls.set(UI_CACHE_DB_DICT_DATA, result.sysAllDictItems, 7 * 24 * 60 * 60 * 1000)
                        commit('SET_TOKEN', result.token)
                        commit('SET_INFO', userInfo)
                        commit('SET_NAME', {
                            username: userInfo.username,
                            realname: userInfo.realname,
                            welcome: welcome()
                        })
                        commit('SET_AVATAR', userInfo.avatar)
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登出
        Logout({commit, state}) {
            return new Promise((resolve) => {
                let logoutToken = state.token;
                commit('SET_TOKEN', '')
                commit('SET_PERMISSIONLIST', [])
                Vue.ls.remove(ACCESS_TOKEN)
                Vue.ls.remove(USER_INFO)
                Vue.ls.remove(USER_NAME)
                Vue.ls.remove(UI_CACHE_DB_DICT_DATA)
                Vue.ls.remove(CACHE_INCLUDED_ROUTES)
                logout(logoutToken).then(() => {
                    if (process.env.VUE_APP_SSO == 'true') {
                        alert("true")
                        let sevice = 'http://' + window.location.host + '/'
                        let serviceUrl = encodeURIComponent(sevice)
                        window.location.href = process.env.VUE_APP_CAS_BASE_URL + '/logout?redirectUrl=' + serviceUrl
                    }
                    // resolve()
                }).catch(() => {
                    resolve()
                })
            })
        },

        saveTenant({commit}, id) {
            Vue.ls.set(TENANT_ID, id, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TENANT', id)
        }


    }
}

export default user
